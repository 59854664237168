import {Component, useEffect, useState} from "react";
import {useParams} from "react-router";
import {EventInfo} from "./models/Models";
import ApiClientFactory from "./models/ApiClientFactory";
import localizedTexts from "./texts/EventV2DetailView.texts";
import ToastMessage from "../common/components/ToastMessage";

const EventV2DetailView = () => {
    const apiClient = ApiClientFactory.GetApiClient();

    const {eventId} = useParams();

    const [eventInfos, setEventInfos] = useState({} as EventInfo);

    const [showToast, setShowToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        loadData();
    }, [])

    async function loadData() {
            var eventInfo = await apiClient.api.eventsV2EventInformationDetail(`${eventId}`);
            if (eventInfo.ok) {
                setEventInfos(eventInfo.data);
            }else{
                let errorCodeTranslation = localizedTexts.getString(`errorCode-${eventInfo.error?.errorCode}`);
                if (!eventInfo.bodyUsed) {
                    const contentType = eventInfo.headers.get('Content-Type');
                    if (contentType !== null && contentType.indexOf("application/json") > -1) {
                        eventInfo.json().then(d => {
                            errorCodeTranslation = localizedTexts.getString(`errorCode-${d?.errorCode}`);
                        });
                    }
                }
                if (!errorCodeTranslation) {
                    errorCodeTranslation = localizedTexts.errorGeneralText ?? "";
                }
                setErrorMessage(errorCodeTranslation);
                setShowToast(true);
            }
    }

    return <div>
        {showToast && (
            <div id="toast-container" className="position-fixed top-0 start-50 translate-middle-x p-3"
                 style={{zIndex: 11}}>
                <ToastMessage id="errorToast" message={errorMessage} onClose={() => setShowToast(false)} role="alert"/>
            </div>
        )}
        <h1>{localizedTexts.title}</h1>
        <h2>{eventInfos?.name}</h2>
        <table className={"table table-striped table-responsive"}>
            <tbody>
            <tr>
                <td>{localizedTexts.labelName}</td>
                <td>{eventInfos?.name}</td>
            </tr>
            <tr>
                <td>{localizedTexts.labelShortName}</td>
                <td>{eventInfos?.shortName}</td>
            </tr>
            <tr>
                <td>{localizedTexts.labelOrganizer}</td>
                <td>{eventInfos?.eventOrganizer}</td>
            </tr>
            <tr>
                <td>{localizedTexts.labelLocation}</td>
                <td>{eventInfos?.eventLocation}</td>
            </tr>
            <tr>
                <td>{localizedTexts.labelParticipantCount}</td>
                <td>{eventInfos?.participantCount}</td>
            </tr>
            </tbody>
        </table>
    </div>

}

export default EventV2DetailView;

