import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Gruppen",
        "headerGroupName": "Gruppenname",
        "headerGroupNumber":"Gruppennummer",
        "headerGroupMembers":"Gruppenteilnehmer",
        "headerActions":"Aktionen",
        "headerOrganization":"Verein",
        "labelProgramDefinition":"Stich",
        "labelOrganization":"Verein",
        "textOrganizationSelect":"Verein auswählen...",
        "labelSelectParticipant":"Gruppenmitglied auswählen",
        "labelNoAvailableParticipants": "Keine weiteren Mitglieder gefunden",
        "labelNewGroup":"Neue Gruppe",
        "labelNewGroupName":"Name",
        "labelNewGroupNumber":"Nummer",
        "labelLastName":"Nachname",
        "labelFirstName":"Vorname",
        "labelStreet":"Strasse",
        "labelCity":"Ort",
        "labelZipCode":"PLZ",
        "labelStreetNr":"Nr.",
        "labelPhoneNr":"Telefon",
        "labelEMail":"E-Mail",
        "labelIban":"IBAN",
        "labelBankName":"Name der Bank",
        "labelShowGroupChef":"Gruppenchef",
        "labelEditGroupNr": "Gruppennummer",
        "labelEditGroupName": "Gruppenname editieren",
        "LabelResult": "Resultat",
        "textGroupChefSaved":"Gruppenchef gespeichert",
        "textGroupAllFieldsMandatory":"Es müssen alle Felder ausgefüllt werden",
        "textErrorWhenSaveGroupName":"Fehler beim Speichern des Gruppennamens",
        "textErrorInvalidGroupName": "ungültiger Gruppenname",
        "buttonSave":"Speichern",
        "textConfirmDelete":"Sind sie sicher das sie entfernen möchten?",
        "shooteroffsetmessage":"Wieviele Schützen wurden bereits auf dieser Seite gedruckt?"
    },
    "fr": {
        "LabelResult": "Résultat",
    },
    "it": {
        "LabelResult": "Risultato",
    },
    "en": {
        "LabelResult": "Result",
    }
});
export default localizedTexts;