import {useParams} from "react-router";
import React, {ChangeEvent, Component} from "react";
import localizedTexts from "./texts/EventParticipantsRegistration.texts";
import {
    Discipline,
    EventRegistratioInformation,
    Group, HttpResponse,
    Organization,
    Participant, ParticipantSearch,
    Person, PersonSearch, RegisterParticipant, ResultSheetDefinition, ShootingProgramDefinition,
    ShootingProgramDefinitionRegistration, Weapon
} from "./models/Models";
import ApiClientFactory from "./models/ApiClientFactory";
import WinFireDropDown, {WinFireDropDownElement} from "../common/components/WinFireDropDown";
import PrintDocument from "../common/Helpers/PrintDocument";
import EventParticipantsRegistrationGroupModal from "./EventParticipantsRegistrationGroupModal";
import $ from "jquery";
import * as bootstrap from 'bootstrap';

function EventParticipantsRegistrationWrapper(){
    let {eventId, participantnumber} = useParams();
    return <EventParticipantsRegistration eventId={eventId} participantNumber={participantnumber} />
}

export class EventParticipantsRegistration extends Component<EventParticipantsRegistrationProps, EventParticipantsRegistrationState>{
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props:EventParticipantsRegistrationProps) {
        super(props);
        this.state = {
            organizations: [],
            allOrganizations: [],
            allWeapons: [],
            selectedOrganization: undefined,
            person: {

            },
            selectedGroup: undefined,
            participantNumber: "",
            startNr: "",
            participantSaved: false,
            eventRegistrationInformation: undefined,
            selectedDiscipline: undefined,
            programSelection: undefined,
            personSearchResult: [],
            isLoading: true,
            personSearchNoResult: false,
            groups: [],
            groupsReady: false,
            groupsProgramDefinition: undefined,
            resultSheets: [],
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        try {
            var result = await this.apiClient.api.eventsOrganizationsDetail(this.props.eventId ?? "");
            if (result.ok) {
                this.setState({organizations: result.data, allOrganizations: result.data.map(o => o)});
            }
        } catch (e: any) {

        }
        try {
            var infos = await this.apiClient.api.eventsEventInformationRegistrationinformationDetail(this.props.eventId ?? "");
            if (infos.ok) {
                let selectedDiscipline : Discipline | undefined = undefined;
                var progs: ShootingProgramDefinitionRegistration[] = [];
                var weapons: Weapon[] = [];
                var resultSheets: ResultSheetDefinition[] = [];
                if (infos.data.disciplines?.length && infos.data.disciplines?.length > 0) {
                    selectedDiscipline = infos.data.disciplines[0];
                    selectedDiscipline.shootingProgramDefinitions?.forEach((s) => {
                        progs.push({
                            passenCount: s.isMandatory ? 1 : 0,
                            isOrdered: s.isMandatory,
                            shootingProgramDefinitionId: s.id,
                            price: s.shootingProgramDefinitionPrices?.find(s => s.isDefault)?.price ?? 0
                        });
                        if (s.isOrderable && s.resultSheetDefinition && resultSheets.findIndex(r => r.id == s.resultSheetDefinition?.id) == -1){
                            resultSheets.push(s.resultSheetDefinition);
                        }
                    });
                    selectedDiscipline.fields?.forEach(f => {
                        f.weapons?.forEach(w => {
                            if (!weapons.find(wl => wl.id == w.id)) {
                                weapons.push(w);
                            }
                        })
                    });
                }

                this.setState({
                    eventRegistrationInformation: infos.data,
                    selectedDiscipline: selectedDiscipline,
                    programSelection: progs,
                    allWeapons: weapons,
                    isLoading: false,
                    resultSheets: resultSheets
                });
                this.loadInitialParticipant();
            }
        } catch (e: any) {
        }
    }

    loadInitialParticipant = async () => {
        if (this.props.participantNumber){
            var licenseNumber = this.props.participantNumber.substring(2, 8);

            var participantResult:HttpResponse<Participant, any>|undefined = undefined;
            try{
                participantResult = await this.apiClient.api.eventsParticipantsByLicenseDetail(licenseNumber, this.props.eventId ?? "");
            }catch{

            }

            if (participantResult && participantResult.ok){
                var person = participantResult.data.person!;
                var displayOrgs = this.state.organizations;
                if (person.organizations) {
                    person.organizations.forEach(o => {
                        o.zipCode="99999";
                        displayOrgs.splice(0, 0, o);
                    });
                }

                const progs:ShootingProgramDefinitionRegistration[] = [];
                this.state.eventRegistrationInformation?.disciplines?.forEach(d  =>
                {
                    const progDefinitions = (d.shootingProgramDefinitions || []).map(s => {
                        var shootingProgramDefinitionRegistration = participantResult?.data.shootingProgramDefinitionRegistrations?.find(sr => {
                            return sr.shootingProgramDefinitionId == s.id
                        });
                        var passenCount = shootingProgramDefinitionRegistration?.passenCount;
                        var shootingProgramDefinitionPrice = s.shootingProgramDefinitionPrices?.find(sd => sd.isDefault);
                        var amount = (passenCount ?? 0) * (shootingProgramDefinitionPrice?.price ?? 0);
                        if (((passenCount ?? 0) > 1) && (shootingProgramDefinitionPrice?.priceWithoutAmmo != null ?? 0)) {
                            amount = (shootingProgramDefinitionPrice?.price ?? 0) + (passenCount! - 1) * shootingProgramDefinitionPrice?.priceWithoutAmmo!;
                        }
                        var isOrdered = shootingProgramDefinitionRegistration?.isOrdered ?? false;
                        var group = shootingProgramDefinitionRegistration?.group;
                        let selectedWeaponId = shootingProgramDefinitionRegistration?.weaponId === undefined ?
                            d.fields?.length === 1 && d.fields[0]?.weapons?.length === 1 ?
                                d.fields[0].weapons[0].id : undefined : shootingProgramDefinitionRegistration?.weaponId;
                        return {
                            shootingProgramDefinitionId: s.id,
                            passenCount: s.isMandatory ? Math.max(1, passenCount ?? 0): (passenCount ?? 0),
                            isOrdered:  s.isMandatory || isOrdered,
                            price: amount,
                            weaponId: selectedWeaponId,
                            group: group
                        } as ShootingProgramDefinitionRegistration;
                    });
                    progs.push(...progDefinitions);
                });

                this.setState({person: person, organizations: displayOrgs, selectedOrganization: participantResult.data.organization, programSelection: progs, participantNumber: participantResult.data.participantNumber??"", startNr: participantResult.data.startNumber??""});
                return;
            }
        }
    }

    isNumeric = (str: string): boolean => {
        return /^\d+$/.test(str);
    };

    selectCommunity = (organization: Organization) => {
        /*let result = await winFireFetcher.fetch(`/api/event/events/${this.props.eventId}/communities/${community.communityNumber}/groups/notfull`);
        if (result.ok) {
            let groups = await result.json();
            this.setState({Groups: groups});
        }
        this.setState({
            SelectedCommunity: community,
            CommunityButtonText: `${community.communityName} ${community.city}`,
            SelectedGroup: null,
            GroupButtonText: localizedTexts.selectgroup!,
            CommunityFilter: "",
            ShowCommunityDropDown: false
        });*/
        this.setState({selectedOrganization: organization});
    }

    selectWeapon = (weapon: Weapon) => {

    }

    selectDiscipline = (discipline: Discipline) => {
        const progs: ShootingProgramDefinitionRegistration[] = [];
        const weapons: Weapon[] = [];
        const previousSelection = this.state.programSelection;

        const getProgramSelection = (s: ShootingProgramDefinition) => {
            const currentProgram = this.state.programSelection?.find(p => p.shootingProgramDefinitionId === s.id);
            let selectedWeapon = currentProgram?.weaponId === undefined ?
                discipline?.fields?.length === 1 && discipline?.fields[0]?.weapons?.length === 1 ?
                    discipline?.fields[0].weapons[0].id : undefined : currentProgram?.weaponId;
            return {
                passenCount: s.isMandatory ? Math.max(1, currentProgram?.passenCount ?? 0): currentProgram?.passenCount ?? 0,
                isOrdered: s.isMandatory || (currentProgram?.isOrdered ?? false),
                shootingProgramDefinitionId: s.id,
                price: s.shootingProgramDefinitionPrices?.find(s => s.isDefault)?.price ?? 0,
                weaponId: selectedWeapon,
                group: currentProgram?.group,
            };
        };

        discipline.shootingProgramDefinitions?.forEach(program => {
            progs.push(getProgramSelection(program));
        });

        // Add any old selections that don't exist in the discipline's shootingProgramDefinitions
        previousSelection?.forEach(oldProg => {
            if (!progs.find(p => p.shootingProgramDefinitionId === oldProg.shootingProgramDefinitionId)) {
                progs.push(oldProg);
            }
        });

        discipline.fields?.forEach(f => {f.weapons?.forEach(w => {
            if (!weapons.find(wl => wl.id == w.id)){
                weapons.push(w);
            }
        })});
        this.setState({selectedDiscipline: discipline, programSelection: progs, allWeapons: weapons});
    }

    updateStartNr = (evt: React.ChangeEvent<HTMLInputElement>)=> {
        this.setState({startNr: evt.currentTarget.value});
    }

    updatePerson = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.searchPerson(evt.currentTarget);
        var person:any = this.state.person;
        if (evt.currentTarget.type == "checkbox"){
            person[evt.currentTarget.id] = evt.currentTarget.checked;
        }else{
            console.log("Id: " + evt.currentTarget.value);
            if (evt.currentTarget.id === "licenseNumber")
            {
                if (this.isNumeric(evt.currentTarget.value) || evt.currentTarget.value === "")
                {
                    person[evt.currentTarget.id] = evt.currentTarget.value;
                }

                // For Polytronic ScoreApp QR Code
                if (evt.currentTarget.value.length > 10)
                {
                    person[evt.currentTarget.id] = evt.currentTarget.value.slice(-6)
                }
            }
            else {
                person[evt.currentTarget.id] = evt.currentTarget.value;
            }
        }
        this.setState({person: person});
    }


    searchTimer:number = 0;
    async searchPerson(inputElement: HTMLInputElement) {
        window.clearTimeout(this.searchTimer);

        this.searchTimer = window.setTimeout(async () => {
            var person: Person | null = null;
            switch (inputElement.id) {
                case "licenseNumber":
                    var licenseNumber = inputElement.value;
                    if (licenseNumber.length == 10 && licenseNumber.startsWith("1")) {
                        licenseNumber = licenseNumber.substring(2, 8);
                    }
                    if (licenseNumber.length == 6) {
                        var participantResult: HttpResponse<Participant, any> | undefined = undefined;
                        try {
                            participantResult = await this.apiClient.api.eventsParticipantsByLicenseDetail(licenseNumber, this.props.eventId ?? "");
                        } catch {

                        }

                        if (participantResult && participantResult.ok) {
                            var participant = participantResult.data;
                            person = this.setParticipant(participant);
                            return;

                        } else {
                            try {
                                var licResult = await this.apiClient.api.eventsPeopleByLicenseDetail(licenseNumber, this.props.eventId ?? "");
                                if (licResult.ok) {
                                    person = licResult.data;
                                } else if (licResult.status == 404) {
                                    alert(localizedTexts.textPersonNotFound)
                                }
                            }catch (e:any){
                                alert(localizedTexts.textPersonNotFound)
                            }
                        }
                    }
                    break;
                case "personNumber":
                    var persNumber = inputElement.value;
                    try {
                        var persResult = await this.apiClient.api.eventsPeopleByPersonNrDetail(persNumber, this.props.eventId ?? "");
                        if (persResult.ok) {
                            person = persResult.data;
                        }
                    } catch (e: any) {
                    }
                    break;
            }

            if (person) {
                this.setPerson(person);
            }
        }, 1000);
    }

    private setParticipant(participant: Participant) {
        var person = participant.person!;
        var displayOrgs = this.state.organizations;
        if (person.organizations) {
            person.organizations.forEach(o => {
                o.zipCode="99999";

                // Check if the organization already exists at the beginning
                if (!displayOrgs.some((org, idx) => idx < 5 && org.number === o.number)) {
                    displayOrgs.splice(0, 0, o);
                }
            });
        }

        const progs:ShootingProgramDefinitionRegistration[] = [];
        this.state.eventRegistrationInformation?.disciplines?.forEach(d  =>
        {
            const progDefinitions = (d.shootingProgramDefinitions || []).map(s => {
                var shootingProgramDefinitionRegistration = participant.shootingProgramDefinitionRegistrations?.find(sr => {
                    return sr.shootingProgramDefinitionId == s.id
                });
                var passenCount = shootingProgramDefinitionRegistration?.passenCount;
                var shootingProgramDefinitionPrice = s.shootingProgramDefinitionPrices?.find(sd => sd.isDefault);
                var amount = (passenCount ?? 0) * (shootingProgramDefinitionPrice?.price ?? 0);
                if (((passenCount ?? 0) > 1) && (shootingProgramDefinitionPrice?.priceWithoutAmmo != null ?? 0)) {
                    amount = (shootingProgramDefinitionPrice?.price ?? 0) + (passenCount! - 1) * shootingProgramDefinitionPrice?.priceWithoutAmmo!;
                }
                var isOrdered = shootingProgramDefinitionRegistration?.isOrdered ?? false;
                var group = shootingProgramDefinitionRegistration?.group;
                let selectedWeaponId = shootingProgramDefinitionRegistration?.weaponId === undefined ?
                    d.fields?.length === 1 && d.fields[0]?.weapons?.length === 1 ?
                        d.fields[0].weapons[0].id : undefined : shootingProgramDefinitionRegistration?.weaponId;
                return {
                    shootingProgramDefinitionId: s.id,
                    passenCount: s.isMandatory ? Math.max(1, passenCount ?? 0): (passenCount ?? 0),
                    isOrdered:  s.isMandatory || isOrdered,
                    price: amount,
                    weaponId: selectedWeaponId,
                    group: group
                } as ShootingProgramDefinitionRegistration;
            });
            progs.push(...progDefinitions);
        });

        this.setState({
            person: person,
            organizations: displayOrgs,
            selectedOrganization: participant.organization,
            programSelection: progs,
            participantNumber: participant.participantNumber ?? "",
            startNr: participant.startNumber ?? ""
        });
        return person;
    }

    searchPersonByObject = async () => {

        var person:PersonSearch = this.state.person;
        person.organization = this.state.selectedOrganization;

        var participantSearch: ParticipantSearch = {
            participantNumber: null,
            personSearch: person
        }

        try {
            var searchResult = await this.apiClient.api.eventsParticipantsSearchCreate(this.props.eventId ?? "", participantSearch);
            if (searchResult.ok && searchResult.data.length > 0) {
                this.setState({personSearchResult: searchResult.data, personSearchNoResult: false});
            } else {
                this.setState({personSearchResult: [], personSearchNoResult: true});
            }
            var personSelector = new bootstrap.Modal(document.getElementById("personSelector")!);
            personSelector.toggle();
        }catch (e:any){

        }
    }

    enterSearch = (evt: React.KeyboardEvent<HTMLInputElement>) => {
        if (evt.key === "Enter"){
            this.searchPersonByObject();
        }
    }

    private setPerson(person: Person) {
        var selectedOrg = undefined;
        var orgs = this.state.organizations;
        if (person.organizations) {
            person.organizations.forEach(o => {
                o.zipCode="99999";

                // Check if the organization already exists at the beginning
                if (!orgs.some((org, idx) => idx < 5 && org.number === o.number)) {
                    orgs.splice(0, 0, o);
                }
            });

            if (person.organizations.length == 1) {
                selectedOrg = person.organizations[0];
            } else if (person.organizations.length > 1) {
                selectedOrg = person.organizations.find(o => o.isAMember);
            }
        }
        this.setState({person: person, organizations: orgs, selectedOrganization: selectedOrg, personSearchResult: [], personSearchNoResult: false});
    }

    nextShooter = () => {
        if (true || this.state.participantSaved || window.confirm(localizedTexts.textNotSavedDialog)){
            this.reFocusLicenseNumber();
            var progs = this.state.selectedDiscipline?.shootingProgramDefinitions?.map(s => {
                return {shootingProgramDefinitionId: s.id, passenCount: s.isMandatory?1:0, isOrdered: s.isMandatory} as ShootingProgramDefinitionRegistration
            })
            this.setState({person: {}, organizations: this.state.allOrganizations.map(o => o), selectedOrganization: undefined, selectedGroup: undefined, participantSaved: false, participantNumber: "", startNr: "", programSelection: progs, personSearchResult: [], personSearchNoResult: false});
        }
    }

    reSend = async () => {
        try {
            var result = await this.apiClient.api.eventsParticipantsResendCreate(this.state.participantNumber ?? "", this.props.eventId ?? "");
            if (result.ok) {
                var okToast = document.getElementById("toast-send-ok");
                if (okToast){
                    var toast = new bootstrap.Toast(okToast, {animation: true, autohide: true, delay: 5000});
                    toast.show();
                }

            } else {
                alert(localizedTexts.textReSendNok);
            }
        } catch (e: any) {

        }
    }

    reFocusLicenseNumber = () => {
        document.getElementById("licenseNumber")?.focus();
    }

    getDisciplinesDropDown(): React.ReactNode {
        var elements:WinFireDropDownElement[] = [];
        if (this.state.eventRegistrationInformation && this.state.eventRegistrationInformation.disciplines) {
            elements = this.state.eventRegistrationInformation?.disciplines?.map(d => {
                return {sourceObject: d, key: `${d.id}`, text: d.name, selectFunction: this.selectDiscipline} as WinFireDropDownElement
            });
        }
        return <WinFireDropDown
            buttonText={this.state.selectedDiscipline?.name?? localizedTexts.labelSelectDiscipline??""}
            elements={elements}
            elementFilter={(a:string, b:Discipline) => {return true}} />
    }

    updateProgramSelection = (shootingProgramDefinition: ShootingProgramDefinition, attr: string, newValue: boolean|string|number|Group|undefined) => {
        var programSelection = this.state.programSelection;
        var updatee:any = programSelection?.find(p => p.shootingProgramDefinitionId == shootingProgramDefinition.id);
        if (updatee){

            if (attr == "isOrdered")
            {
                newValue = newValue || (shootingProgramDefinition.isMandatory??false);
                updatee.passenCount =  newValue?Math.max(1, updatee.passenCount):0;
                let selectedWeaponId = updatee.weaponId === undefined ?
                    this.state.selectedDiscipline?.fields?.length === 1 && this.state.selectedDiscipline?.fields[0]?.weapons?.length === 1 ?
                        this.state.selectedDiscipline?.fields[0].weapons[0].id : undefined : updatee.weaponId;
                updatee.weaponId = selectedWeaponId;

            }
            if (attr == "weaponId"){
                for (var programSelectionElement of programSelection!) {
                    if (!programSelectionElement.weaponId){
                        var weaponUpdater:any = programSelectionElement;
                        weaponUpdater.weaponId = newValue;
                    }
                }
                updatee.isOrdered = true;
                updatee.passenCount =  newValue?Math.max(1, updatee.passenCount):0;
            }
            if (attr == "passenCount"){
                if (newValue && shootingProgramDefinition.maxPassen && newValue > shootingProgramDefinition.maxPassen){
                    newValue =  shootingProgramDefinition.maxPassen;
                }
            }
            updatee[attr] = newValue;
            this.setState({programSelection: programSelection});
        }
    }

    prepareGroupsModal = async (shootingProgramDefinition: ShootingProgramDefinition) => {
        if (!this.state.selectedOrganization) {
            this.setState({groupsReady: true, groups: [], groupsProgramDefinition: undefined})
            return;
        }

        try {
            var groupsResult = await this.apiClient.api.eventsOrganizationsGroupsDetail(this.state.selectedOrganization.number ?? "", shootingProgramDefinition.id ?? 0, this.props.eventId ?? "");
            if (groupsResult.ok) {
                this.setState({
                    groupsReady: true,
                    groups: groupsResult.data,
                    groupsProgramDefinition: shootingProgramDefinition
                })
            }
        } catch (e: any) {

        }
    }

    saveGroupModal = (group: Group|undefined, shootingProgramDefinition: ShootingProgramDefinition) => {
        if (group){
            group.organizationNumber = this.state.selectedOrganization!.number;
            group.shootingProgramDefinitionId = shootingProgramDefinition.id;
        }

        this.updateProgramSelection(shootingProgramDefinition, "group", group)
    }

    save = async ():Promise<Participant|undefined> => {

        var participant: RegisterParticipant = {};
        participant.person = this.state.person;
        participant.organization = this.state.selectedOrganization;
        participant.shootingProgramDefinitionRegistrations = this.state.programSelection;
        participant.participantNumber = this.state.participantNumber;
        participant.startNumber = this.state.startNr;

        if (participant.shootingProgramDefinitionRegistrations?.find(s => s.isOrdered && (!s.weaponId || s.weaponId == 0)) != undefined) {
            alert(localizedTexts.textWeaponNotSelectedDialog);
            return;
        }

        try {
            var result = await this.apiClient.api.eventsParticipantsCreate(this.props.eventId ?? "", participant);
            if (result.ok) {
                var okToast = document.getElementById("toast-save-ok");
                if (okToast){
                    var toast = new bootstrap.Toast(okToast, {animation: true, autohide: true, delay: 5000});
                    toast.show();
                }
                this.setState({participantNumber: result.data.participantNumber ?? ""})
                return result.data;
            }
        } catch (ex: any) {
            if (ex.error && ex.error.errorCode && ex.error.errorCode == "PERSONMODELERROR") {
                alert(localizedTexts.textParticipantSavenotSuccessfulPERSONMODELERROR)
            } else {
                alert(localizedTexts.textParticipantSavenotSuccessful)
            }
        }
    }

    saveAndPrint = async (resultsheetId: number, isBarcodeSheet: boolean) => {
        var participant = await this.save();
        if (participant && participant.participantNumber){
            try {
                var offset = 0;
                if (isBarcodeSheet){
                    var offsetSelect = prompt(localizedTexts.shooteroffsetmessage);
                    offset = parseInt(offsetSelect ?? "0");
                }
                var result = await this.apiClient.api.eventsParticipantsResultsheetDetail(participant.participantNumber, resultsheetId, this.props.eventId ?? "", {offset: offset});
                if (result.ok) {
                    await PrintDocument.printPdfDocument(result);
                    this.nextShooter();
                }
            }catch (e:any){

            }
        }
    }

    render(){
        if (this.state.isLoading){
            return <div>
                <h1>{localizedTexts.title}</h1>
                <h4>{localizedTexts.textSiteLoading}</h4>
            </div>
        }

        return <div>
            <h1>{localizedTexts.title}</h1>
            <div className={"container-flex"}>
                <div className={"row pb-4"}>
                    <div className={"col-sm-6 px-3"}>
                        <h3>{localizedTexts.labelOrganization}</h3>
                        <WinFireDropDown
                            buttonText={this.state.selectedOrganization ? `${this.state.selectedOrganization.name} ${this.state.selectedOrganization.city}` : localizedTexts.labelSelectOrganization ?? ""}
                            elements={this.state.organizations.map(o => {
                                return {
                                    text: `${o.name} ${o.city}`,
                                    key: `${o.zipCode ?? ""}${o.number}`,
                                    selectFunction: this.selectCommunity,
                                    sourceObject: o
                                }
                            })}
                            elementFilter={this.communityFilter}/>
                    </div>
                    <div className={"col-sm-1"}>
                    </div>
                    <div className={"col-sm-5"}>
                        <h3>{localizedTexts.labelDiscipline}</h3>
                        {
                            this.getDisciplinesDropDown()
                        }
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-sm-6 px-3"}>
                        <h3>{localizedTexts.labelShooter}</h3>
                        <div className={"row"}>
                            <div className={"col-6"}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="shooterNumber"
                                           placeholder={localizedTexts.labelBarcode}
                                           value={this.state.participantNumber}
                                           readOnly={true}/>
                                    <label htmlFor="shooterNumber">{localizedTexts.labelBarcode}</label>
                                </div>
                            </div>

                            <div className={"col-2"}>
                                <div className="form-floating mb-2">
                                    <input type="text" className="form-control" id="startNumber"
                                           placeholder={localizedTexts.labelBarcode}
                                           value={this.state.startNr}
                                           onChange={this.updateStartNr}
                                    />
                                    <label htmlFor="startNumber">{localizedTexts.labelStartNr}</label>
                                </div>
                            </div>
                            <div className={"col-1"}>

                            </div>
                            <div className={"col-3"}>
                                <button style={{width: '90%', height: "80%"}}
                                        className={"btn btn-primary"}
                                        onClick={this.searchPersonByObject}><span style={{fontSize: 'x-large'}}
                                                                                  className={"bi-search"}/></button>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-6"}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="personNumber"
                                           placeholder={localizedTexts.labelPersonNumber}
                                           value={this.state.person.personNumber ?? ""}
                                           onChange={this.updatePerson}
                                           autoComplete={"off"}/>
                                    <label htmlFor="personNumber">{localizedTexts.labelPersonNumber}</label>
                                </div>
                            </div>
                            <div className={"col-6"}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control"
                                           id="licenseNumber"
                                           placeholder={localizedTexts.labelLicenseNumber}
                                           value={this.state.person.licenseNumber ?? ""}
                                           onChange={this.updatePerson}
                                           autoFocus={true}
                                           autoComplete={"off"}/>
                                    <label htmlFor="licenseNumber">{localizedTexts.labelLicenseNumber}</label>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-6"}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="lastName"
                                           placeholder={localizedTexts.labelLastName}
                                           value={this.state.person.lastName ?? ""}
                                           onChange={this.updatePerson}
                                           onKeyDown={this.enterSearch}
                                           autoComplete={"off"}/>
                                    <label htmlFor="lastName">{localizedTexts.labelLastName}</label>
                                </div>
                            </div>
                            <div className={"col-6"}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="firstName"
                                           placeholder={localizedTexts.labelFirstName}
                                           value={this.state.person.firstName ?? ""}
                                           onChange={this.updatePerson}
                                           onKeyDown={this.enterSearch}
                                           autoComplete={"off"}/>
                                    <label htmlFor="firstName">{localizedTexts.labelFirstName}</label>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-6"}>
                                <div className="form-floating mb-3">
                                    <input type="date" className="form-control" id="birthday"
                                           placeholder={localizedTexts.labelBirthdate}
                                           value={this.state.person.birthday ?? ""}
                                           onChange={this.updatePerson}
                                           onKeyDown={this.enterSearch}
                                           autoComplete={"off"}/>
                                    <label htmlFor="birthday">{localizedTexts.labelBirthdate}</label>
                                </div>
                            </div>
                            <div className={"col-6"}>

                                <div className="form-check form-check-inline" style={{paddingTop: "15px", paddingLeft: "30px"}}>
                                    <input type={"checkbox"} className={"form-check-input"} id={"isWoman"}
                                           checked={this.state.person.isWoman}
                                           onChange={this.updatePerson}/>
                                    <label className={"form-check-label"} htmlFor="isWoman"> {localizedTexts.labelIsWoman}</label>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-6"}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="street"
                                           placeholder={localizedTexts.labelStreet}
                                           value={this.state.person.street ?? ""}
                                           onChange={this.updatePerson}
                                           onKeyDown={this.enterSearch}
                                           autoComplete={"off"}/>
                                    <label htmlFor="street">{localizedTexts.labelStreet}</label>
                                </div>
                            </div>
                            <div className={"col-2"}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="zipCode"
                                           placeholder={localizedTexts.labelZipCode}
                                           value={this.state.person.zipCode ?? ""}
                                           onChange={this.updatePerson}
                                           onKeyDown={this.enterSearch}
                                           autoComplete={"off"}/>
                                    <label htmlFor="zipCode">{localizedTexts.labelZipCode}</label>
                                </div>
                            </div>
                            <div className={"col-4"}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="city"
                                           placeholder={localizedTexts.labelCity}
                                           value={this.state.person.city ?? ""}
                                           onChange={this.updatePerson}
                                           onKeyDown={this.enterSearch}
                                           autoComplete={"off"}/>
                                    <label htmlFor="city">{localizedTexts.labelCity}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"col-sm-1"}>

                    </div>
                    <div className={"col-sm-5"}>
                        <table className={"table table-striped align-middle"}>
                            <tbody>
                            {
                                this.state.selectedDiscipline?.shootingProgramDefinitions?.filter(s => s.isOrderable).sort((a, b) => {
                                    return (a.visualOrder ?? 0) - (b.visualOrder ?? 0)
                                }).map(s => {
                                    var programSelection = this.state.programSelection?.find(p => s.id == p.shootingProgramDefinitionId);
                                    return <tr key={s.name}>
                                    <td><input type={"checkbox"}
                                                   readOnly={s.isMandatory}
                                                   checked={programSelection?.isOrdered}
                                                   onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                                       this.updateProgramSelection(s, "isOrdered", evt.currentTarget.checked)
                                                   }}/></td>
                                        <td><input type={"number"}
                                                   readOnly={s.isMandatory}
                                                   max={s.maxPassen}
                                                   min={programSelection?.isOrdered ? 1 : 0}
                                                   value={programSelection?.passenCount}
                                                   onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                                       this.updateProgramSelection(s, "passenCount", evt.currentTarget.valueAsNumber)
                                                   }}/></td>
                                        <td>{s.name}</td>
                                        <td>
                                            <WinFireDropDown
                                                buttonText={this.state.allWeapons.find(w => w.id == this.state.programSelection?.find(p => s.id == p.shootingProgramDefinitionId)?.weaponId)?.shortName ?? localizedTexts.labelSelectWeapon ?? ""}
                                                elements={this.state.allWeapons.map(w => {
                                                    return {
                                                        text: w.shortName ?? "",
                                                        key: w.shortName ?? "",
                                                        selectFunction: (weapon: Weapon) => {
                                                            this.updateProgramSelection(s, "weaponId", weapon.id ?? 0)
                                                        },
                                                        sourceObject: w
                                                    }
                                                })}
                                                elementFilter={(a: string, b: Weapon) => {
                                                    return true;
                                                }}/>
                                        </td>
                                        <td>
                                            {
                                                s.isGroupExercise ?
                                                    <button
                                                        className={"btn btn-primary"}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#groupRegistration"
                                                        onClick={() => {
                                                            this.prepareGroupsModal(s)
                                                        }}>{programSelection?.group ? programSelection.group.groupName : localizedTexts.labelGroup}</button> :
                                                    ""
                                            }
                                        </td>
                                        <td className={"text-end"}>
                                            CHF&nbsp;
                                            {
                                                this.getShootingProgramPrice(s, programSelection)
                                            }
                                        </td>
                                    </tr>
                                })
                            }
                            <tr key={"totalAmount"}>
                                <td colSpan={5}>{localizedTexts.labelTotalAmount}</td>
                                <td className={"text-end"}>CHF&nbsp;
                                    {
                                        this.getTotalPrice()
                                    }

                                    <br/>
                                    <div hidden={this.state.eventRegistrationInformation?.disciplines?.length == 1}>
                                        <strong>
                                            CHF&nbsp;
                                            {
                                                this.getGrandTotalPrice()
                                            }
                                        </strong>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-2"}>
                        <button className={"btn btn-primary"}
                                style={{width: "98%"}}
                                onClick={this.save}>{localizedTexts.buttonSave}</button>
                    </div>

                    {
                        this.getPrintButtons()
                    }


                    <div className={"col-2"}>
                        <button className={"btn btn-outline-primary"}
                                style={{width: "98%"}}
                                onClick={this.nextShooter}>{localizedTexts.buttonNextParticipant}</button>
                    </div>
                    <div className={"col-2"}>
                        <button className={"btn btn-outline-primary"}
                                style={{width: "98%"}}
                                onClick={this.reSend}>{localizedTexts.buttonReSend}</button>
                    </div>
                </div>
            </div>
            {
                this.getPersonSelectModal()
            }
            <EventParticipantsRegistrationGroupModal
                modalId={"groupRegistration"}
                saveHandler={this.saveGroupModal}
                groups={this.state.groups}
                ready={this.state.groupsReady}
                organizationSelected={this.state.selectedOrganization != undefined}
                shootingProgramDefinition={this.state.groupsProgramDefinition}/>

            <div className="toast-container position-absolute p-3 top-0 start-50 translate-middle-x"
                 id="toastContainerPlacement">
                <div id={"toast-save-ok"}
                     className="toast align-items-center text-white bg-success border-0"
                     role="alert"
                     aria-live="assertive" aria-atomic="true">
                    <div className="d-flex">
                        <div className="toast-body">
                            {localizedTexts.textParticipantSaved}
                        </div>
                        <button type="button" className="btn-close btn-close-white me-2 m-auto"
                                data-bs-dismiss="toast"
                                aria-label="Close"></button>
                    </div>
                </div>
                <div id={"toast-send-ok"}
                     className="toast align-items-center text-white bg-success border-0"
                     role="alert"
                     aria-live="assertive" aria-atomic="true">
                    <div className="d-flex">
                        <div className="toast-body">
                            {localizedTexts.textReSendOk}
                        </div>
                        <button type="button" className="btn-close btn-close-white me-2 m-auto"
                                data-bs-dismiss="toast"
                                aria-label="Close"></button>
                    </div>
                </div>
            </div>


        </div>
    }


    getPrintButtons() {
        var col = 12 / this.state.resultSheets.length;

        return <div className={"col-6"}>
            <div className={"row"}>
                {this.state.resultSheets.map((rs) => {
                    return <div className={`col-${col.toFixed(0)}`}>
                        <button className={"btn btn-primary"}
                                style={{width: "98%"}}
                                onClick={() => this.saveAndPrint(rs.id!, rs.isBarcodeSheet!)}>{localizedTexts.buttonSaveAndPrint?.replace("{0}", rs.name!)}</button>
                    </div>
                })
                }
            </div>
        </div>
    }

    getPersonSelectModal() {
        return <div id={"personSelector"} className="modal fade modal-xl" tabIndex={-1} aria-labelledby="Select Person"
                    aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                        <h5 className="modal-title" id="newEventModalLabel">{localizedTexts.modalSearchTitle}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.setState({personSearchResult: [], personSearchNoResult: false})}></button>
                    </div>
                    <div className="modal-body">
                        <div className={"shadow p-2 rounded"}>
                            {
                                this.state.personSearchResult.length == 0 && !this.state.personSearchNoResult
                                    ? <div className={"spinner-border"} role={"status"} /> : ""
                            }
                            <table className={"table table-striped table-responsive"}>
                                <tbody>
                                {
                                    this.state.personSearchResult.slice(0, 15).map((p) => {
                                        var organization = p.person?.organizations?.find(o => o.isAMember);

                                        return <tr>
                                            <td>{p.person?.lastName}</td>
                                            <td>{p.person?.firstName}</td>
                                            <td>{p.person?.city}</td>
                                            <td>{p.person?.birthday}</td>
                                            <td>{organization?.city} {organization?.name}</td>
                                            <td><button className={"btn btn-primary"}
                                                        data-bs-dismiss="modal"
                                                        onClick={() => {p.participantId == 0 ? this.setPerson(p.person!) : this.setParticipant(p)}}>{localizedTexts.modalSelectLabel}</button></td>
                                        </tr>
                                    })
                                }
                                {
                                    <tr>
                                        {
                                            this.state.personSearchResult.length > 15 ?
                                                <td colSpan={6}>{localizedTexts.modalMorePeopleLabel} ({this.state.personSearchResult.length - 15})</td>
                                                :
                                                ""
                                        }

                                    </tr>
                                }
                                {
                                    this.state.personSearchNoResult ? <tr><td>{localizedTexts.textSearchNoResults}</td></tr> : ""
                                }
                                </tbody>
                            </table>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    private getShootingProgramPrice(s: ShootingProgramDefinition, programSelection: ShootingProgramDefinitionRegistration | undefined) {
        return this.getShootingProgramPriceNumber(s, programSelection).toFixed(2);
    }

    private getShootingProgramPriceNumber(s: ShootingProgramDefinition, programSelection: ShootingProgramDefinitionRegistration|undefined) {
        var singlePrice = this.getSinglePriceForShootingProgram(s, programSelection);
        var amountOrdered = this.state.programSelection?.find(p => s.id == p.shootingProgramDefinitionId)?.passenCount;

        var nachdoppelPrice = (singlePrice ?? 0) - (this.getNachdoppelPriceSubForShootingProgram(s, programSelection)?? 0);

        if ((amountOrdered ?? 0 > 1) && (nachdoppelPrice != 0))
        {
            return ((singlePrice ?? 0) + nachdoppelPrice * ((amountOrdered ?? 0) -1));
        }
        return ((singlePrice ?? 0) * (amountOrdered ?? 0));
    }

    private getSinglePriceForShootingProgram(s: ShootingProgramDefinition, programSelection: ShootingProgramDefinitionRegistration|undefined){
        var order = this.state.programSelection?.find(p => s.id == p.shootingProgramDefinitionId);

        if (!order || !order.isOrdered || !programSelection){
            return 0;
        }

        var ammoIncluded = true;
        outerLoop:
            for (const discipline of this.state.eventRegistrationInformation?.disciplines || []) {
                for (const field of discipline.fields || []) {
                    for (const weapon of field.weapons || []) {
                        if (weapon.id === order.weaponId) {
                            ammoIncluded = weapon.priceIncludingAmmo ?? true;
                            break outerLoop; // Exit both loops once the weapon is found
                        }
                    }
                }
            }

        if (this.state.person && this.state.person.birthday){
            var ageYears = new Date().getFullYear() - Number(this.state.person.birthday.slice(0, 4));
            var ageCategoryPrice = s.shootingProgramDefinitionPrices?.find(sp => ageYears >= (sp.ageCategory?.ageFrom ?? 0) && ageYears <= (sp.ageCategory?.ageTo ?? 0));
            if (ageCategoryPrice){
                if (programSelection.group && (ageCategoryPrice.groupPriceAdd??0) > 0){
                    return ageCategoryPrice.groupPriceAdd;
                }

                return ammoIncluded ? ageCategoryPrice.price : ageCategoryPrice.priceWithoutAmmo;
            }else{
                ageCategoryPrice =  s.shootingProgramDefinitionPrices?.find(s => s.isDefault);
                if (programSelection.group && ageCategoryPrice && (ageCategoryPrice.groupPriceAdd??0) > 0){
                    return ageCategoryPrice.groupPriceAdd;
                }

                return ammoIncluded ? ageCategoryPrice?.price??0 : ageCategoryPrice?.priceWithoutAmmo??0;
            }
        }
        else{
            ageCategoryPrice =  s.shootingProgramDefinitionPrices?.find(s => s.isDefault);
            if (programSelection.group && ageCategoryPrice && (ageCategoryPrice.groupPriceAdd??0) > 0){
                return ageCategoryPrice.groupPriceAdd;
            }

            return ammoIncluded ? ageCategoryPrice?.price??0 : ageCategoryPrice?.priceWithoutAmmo??0;
        }

        return 0;
    }

    private getNachdoppelPriceSubForShootingProgram(s: ShootingProgramDefinition, programSelection: ShootingProgramDefinitionRegistration|undefined)
    {
        var order = this.state.programSelection?.find(p => s.id == p.shootingProgramDefinitionId);

        if (!order || !order.isOrdered || !programSelection){
            return 0;
        }

        if (this.state.person && this.state.person.birthday){
            var ageYears = new Date().getFullYear() - Number(this.state.person.birthday.slice(0, 4));
            var ageCategoryPrice = s.shootingProgramDefinitionPrices?.find(sp => ageYears >= (sp.ageCategory?.ageFrom ?? 0) && ageYears <= (sp.ageCategory?.ageTo ?? 0));
            if (ageCategoryPrice){
                return ageCategoryPrice.nachdoppelPriceSub;
            }else{
                ageCategoryPrice = s.shootingProgramDefinitionPrices?.find(s => s.isDefault);
                return ageCategoryPrice?.nachdoppelPriceSub?? 0;
            }
        }
        else{
            ageCategoryPrice =  s.shootingProgramDefinitionPrices?.find(s => s.isDefault);
            return ageCategoryPrice?.nachdoppelPriceSub?? 0;
        }

        return 0;
    }

    private getTotalPrice() {
        var orderedElements = this.state.selectedDiscipline?.shootingProgramDefinitions?.filter(s => this.state.programSelection?.find(p => s.id == p.shootingProgramDefinitionId)?.isOrdered) ?? [];
        var sum = 0.0;
        orderedElements.forEach(s => sum += this.getShootingProgramPriceNumber(s, this.state.programSelection?.find(ps => ps.shootingProgramDefinitionId == s.id)));
        return sum.toFixed(2);
    }

    private getGrandTotalPrice() {
        var sum = 0.0;
        this.state.eventRegistrationInformation?.disciplines?.forEach(d  => {
            const orderedElements = d.shootingProgramDefinitions?.filter(s => this.state.programSelection?.find(p => s.id == p.shootingProgramDefinitionId)?.isOrdered) ?? [];
            orderedElements.forEach(s => sum += this.getShootingProgramPriceNumber(s, this.state.programSelection?.find(ps => ps.shootingProgramDefinitionId == s.id)));
        });
        return sum.toFixed(2);
    }

    private communityFilter(filter: string, c: Organization): boolean {
        let filterElements = filter.toLowerCase().split(' ');

        let allFilters = filterElements.map(f => {
            return c.name!.toLowerCase().indexOf(f) > -1 || c.city!.toLowerCase().indexOf(f) > -1;
        })

        return allFilters.every(v => v);
    }
}

interface EventParticipantsRegistrationState {
    isLoading: boolean;
    organizations: Organization[];
    allOrganizations: Organization[];
    allWeapons: Weapon[];
    selectedOrganization: Organization | undefined;
    person: Person;
    selectedGroup: Group | undefined;
    participantNumber: string;
    startNr: string;
    participantSaved: boolean;
    eventRegistrationInformation: EventRegistratioInformation | undefined;
    selectedDiscipline: Discipline | undefined;
    programSelection: ShootingProgramDefinitionRegistration[] | undefined;
    personSearchResult: Participant[];
    personSearchNoResult: boolean;
    resultSheets: ResultSheetDefinition[];

    groups: Group[],
    groupsReady: boolean,
    groupsProgramDefinition: ShootingProgramDefinition | undefined
}

interface EventParticipantsRegistrationProps {
    eventId: string | undefined;
    participantNumber: string | undefined;
}

export default EventParticipantsRegistrationWrapper;