import {NavLink} from "react-router-dom";
import localizedTexts from "./texts/EventV2Navigation.texts";

const EventV2Navigation : React.FC = () => {

    return  <div className="btn-group py-2" role="group" aria-label="Vertical button group">
        <NavLink to={"overview"} className="btn btn-outline-dark">
            <span className={"bi-book"}> </span>{localizedTexts.overview}
        </NavLink>
        <NavLink to={"organisationoverview"} className="btn btn-outline-dark">
            <span className={"bi-people"}> </span>{localizedTexts.organistaionoverview}
        </NavLink>
        <NavLink to={"shootingrangemanagement/Overview"} className="btn btn-outline-dark">
            <span className={"bi-book"}> </span>{localizedTexts.shootingRangeManagement}
        </NavLink>
    </div>
}

export default EventV2Navigation;