import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Rangieren",
        "labelSelection":"Auswahl",
        "labelShootingRange":"Schiessstand",
        "labelDate":"Datum",
        "labelOrganization": "Verein",
        "labelDiscipline":"Disziplin",
        "labelFrom":"Von",
        "labelTo":"Nach",
        "labelSwitchEntries":"Tauschen",
        "labelClear":"Abbrechen",
        "labelMorning":"Morgens",
        "labelLockOrganization":"Vereinsrangeure fixieren",
        "headerLaneNumber":"Scheibe",
        "errorGeneralText":"Ein Fehler ist aufgetreten. Bitte nochmals versuchen."
    },
    "fr": {
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;