import * as React from 'react';
import {Route, Routes} from "react-router-dom";

import MainNavigation from "./modules/common/MainNavigation";
import ModuleNavigation from "./modules/common/ModuleNavigation";
import Home from "./modules/common/Home";
import UserProfile from "./modules/common/UserProfile";

import CoreMainView from "./modules/core/CoreMainView";
import EventMainView from "./modules/event/EventMainView";

import "./styles/style.scss";
import "bootstrap"
import "bootstrap-icons/font/bootstrap-icons.min.css"
import EventOverview from "./modules/event/EventOverview";
import EventV2MainView from "./modules/eventV2/EventV2MainView";


function App(){

    return <div className={"container-fluid px-0"}>
        <MainNavigation />
        <ModuleNavigation>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/me" element={<UserProfile />} />
                <Route path="/core/*" element={<CoreMainView />} />
                <Route path={"/event"} element={<EventOverview/>}/>
                <Route path="/event/*" element={<EventMainView />} />
                <Route path="/eventv2/*" element={<EventV2MainView />} />
            </Routes>
        </ModuleNavigation>
    </div>;
}


export default App;