import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "overview" : "Übersicht",
        "participants":"Teilnehmer",
        "groups":"Gruppen",
        "organistaionoverview":"Vereinsübersicht",
        "management": "Einstellungen",
        "scorelists":"Ranglisten",
        "statistics":"Statistiken",
        "shootingRangeManagement":"Schiessanlagenverwaltung"
    },
    "fr": {
        "overview" : "Aperçu",
        "participants":"Participant",
        "groups":"Groupes",
        "organistaionoverview":"Vue d'ensemble de l'association",
        "management": "Paramètres",
        "scorelists":"Classements",
        "statistics":"Statistiques"
    },
    "it": {
        "overview" : "Panoramica",
        "participants":"Partecipanti",
        "groups":"Gruppi",
        "organistaionoverview":"Panoramica dell'associazione",
        "management": "Impostazioni",
        "scorelists":"Classifiche",
        "statistics":"Statistiche",
    },
    "en": {
        "overview": "Overview",
        "participants": "Participants",
        "groups": "Groups",
        "organizationoverview": "Organization Overview",
        "management": "Settings",
        "scorelists": "Rankings",
        "statistics": "Statistics"
    }
});
export default localizedTexts;