import {Routes} from "react-router";
import {Route} from "react-router-dom";
import React, {Fragment} from "react";
import EventV2Navigation from "./navigations/EventV2Navigation";
import EventV2DetailView from "./EventV2DetailView";
import EventV2OrganisationOverview from "./EventV2OrganisationOverview";
import EventV2TimeSlotReservation from "./EventV2TimeSlotReservation";
import EventV2ShootingRangeManagementNavigation from "./navigations/EventV2ShootingRangeManagementNavigation";

const EventV2MainView : React.FC = () => {

    return <div className={"container-fluid"}>
        <div className={"row"}>
            <div>
                <Routes>
                    <Route path={"/:eventId/*"} element={<EventV2Navigation />}/>
                </Routes>
            </div>
            <div>
                <Routes>
                    <Route path={"/:eventId/shootingrangemanagement/*"} element={<EventV2ShootingRangeManagementNavigation /> } />
                </Routes>
            </div>
            <br/>
            <div className={"row"}>
                <Fragment>
                    <Routes>
                        <Route path={"/:eventId/overview"} element={<EventV2DetailView />}/>
                        <Route path={"/:eventId/shootingrangemanagement/Overview"} element={<EventV2TimeSlotReservation /> } />
                        <Route path={"/:eventId/shootingrangemanagement/timeslotreservation"} element={<EventV2TimeSlotReservation /> } />
                    </Routes>
                    <Routes>
                        <Route path={"/:eventId/organisationoverview"} element={<EventV2OrganisationOverview />}/>
                    </Routes>
                </Fragment>

            </div>
        </div>
    </div>
}

export default EventV2MainView;