import React, { useEffect } from 'react';
import * as bootstrap from 'bootstrap';

interface ToastProps {
  message: string;
  onClose: () => void;
  role: string;
  id: string;
}

const ToastMessage: React.FC<ToastProps> = ({ message, onClose, role, id }) => {
  useEffect(() => {
    const toastEl = document.getElementById(id)!;
    const toast = new bootstrap.Toast(toastEl, { delay: 3000 });
    toast.show();
    toastEl.addEventListener('hidden.bs.toast', onClose);
    return () => {
      toastEl.removeEventListener('hidden.bs.toast', onClose);
    };
  }, [onClose]);

  return <div
          id={id}
          className="toast align-items-center text-bg-danger border-0"
          role={role}
          aria-live="assertive"
          aria-atomic="true"
      >
        <div className="d-flex">
          <div className="toast-body">
            {message}
          </div>
          <button
              type="button"
              className="btn-close btn-close-white me-2 m-auto"
              data-bs-dismiss="toast"
              aria-label="Close"
          ></button>
        </div>
      </div>;
};

export default ToastMessage;