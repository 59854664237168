import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import './styles/styles.css';
import ApiClientFactory from "./models/ApiClientFactory";
import localizedTexts from "./texts/EventV2OrganisationOverview.text";
import WinFireDropDown, { WinFireDropDownElement } from "../common/components/WinFireDropDown";
import {Discipline, EventRegistrationInformation, Participant, ParticipantExercises, ShootingProgramDefinition, TimeSlotInformation} from "./models/Models";
import ToastMessage from "../common/components/ToastMessage";


const EventOrganisationOverview = () => {
    const { eventId } = useParams<{ eventId: string }>();
    const apiClient = ApiClientFactory.GetApiClient();
    const [disciplinesRegistered, setDisciplinesRegistered] = useState<Discipline[]>([]);
    const [selectedDiscipline, setSelectedDiscipline] = useState({} as Discipline)

    const [organizations, setOrganizations] = useState<{ organizationNumber: string, organizationName: string }[]>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<string | undefined>(undefined);
    const [selectedOrganizationNumber, setSelectedOrganizationNumber] = useState<string | undefined>(undefined);
    const [availableParticipants, setAvailableParticipants] = useState<ParticipantExercises[]>([]);
    const [availableShootingPrograms, setAvailableShootingPrograms] = useState<ShootingProgramDefinition[]>([]);
    const [search, setSearch] = useState<string>("");
    const [showToast, setShowToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [eventInfo, setEventInfo] = useState<EventRegistrationInformation>({});
    const [timeSlotData, setTimeSlotData] = useState<TimeSlotInformation[]>([]);

    useEffect(() => {
        loadData();
    },[])

    async function loadData() {
        try {
            const eventDetailsResponse = await apiClient.api.eventsV2EventInformationRegistrationinformationDetail(eventId ?? "");
            if (eventDetailsResponse.ok) {
                setEventInfo(eventDetailsResponse.data);

                if (eventDetailsResponse.data.disciplines && eventDetailsResponse.data.disciplines.length > 0) {
                    setSelectedDiscipline(eventDetailsResponse.data.disciplines[0]);
                }

                //setAvailableShootingPrograms(selectedDiscipline?.shootingProgramDefinitions ?? []);
            }

            const results = await apiClient.api.eventsV2ParticipantsDetail(eventId ?? "");
            if (results.ok) {
                const organizationNumbers = results.data
                    .map(participant => participant.organization?.number)
                    .filter((number): number is string => number !== undefined);

                const fetchedOrganizations: { organizationNumber: string, organizationName: string }[] = [{
                    organizationName: localizedTexts.textOrganizationSelect ?? "",
                    organizationNumber: ""
                }];
                const orgs = await apiClient.api.eventsV2OrganizationsDetail(eventId ?? "");
                if (orgs.ok) {
                    orgs.data.forEach(o => {
                        if (organizationNumbers.find(org => org === o.number ?? "")) {
                            fetchedOrganizations.push({ organizationNumber: o.number ?? "", organizationName: o.name ?? "" });
                        }
                    });
                }
                setAvailableParticipants(results.data);
                setOrganizations(fetchedOrganizations);
            }

        } catch (e: any) {
            console.error(e);
        }
    }

    const selectOrganization = async (organization: { organizationNumber: string, organizationName: string }) => {
        if (organization.organizationName === localizedTexts.textOrganizationSelect ?? "") {
            setSelectedOrganization(undefined);
            setSelectedOrganizationNumber(undefined);
        } else {
            setSelectedOrganization(organization.organizationName);
            setSelectedOrganizationNumber(organization.organizationNumber);
            await fetchParticipantExerciseData(organization.organizationNumber);
            await fetchTimeSlotData(organization.organizationNumber);
        }
    }

    async function fetchParticipantExerciseData(organizationNumber: string) {
        const results = await apiClient.api.eventsV2ParticipantsByOrganizationDetail(organizationNumber, eventId ?? "");
        if (results.ok) {
            const participants = results.data;
            setAvailableParticipants(participants);

            const uniqueDisciplineIds = new Set<number>();
            results.data.forEach((participantExercise) => {
                participantExercise.disciplineIds?.forEach((disciplineId) => {
                    uniqueDisciplineIds.add(disciplineId!);
                });
            });

            const uniqueDisciplineIdsArray = Array.from(uniqueDisciplineIds);

            const uniqueDisciplinesRegistered = uniqueDisciplineIdsArray.map((id) => eventInfo.disciplines?.find(d => d.id === id)!);
            setSelectedDiscipline(uniqueDisciplinesRegistered[0]);
            setDisciplinesRegistered(uniqueDisciplinesRegistered);
        } else {
            console.error('Failed to fetch participants:', results.statusText);
        }
    }

    async function fetchTimeSlotData(organizationNumber: string) {
        const results = await apiClient.api.eventsV2OrganizationsTimeslotsDetail(organizationNumber, eventId ?? "");
        if (results.ok) {
            let timeslotData = results.data;
            setTimeSlotData(timeslotData);
        } else {
            console.error('Failed to fetch timeslots:', results.statusText);
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.toLowerCase());
    }

    function addMinutes(date: Date, minutes: number): Date {
        date.setMinutes(date.getMinutes() + minutes);
        return date;
    }

    return <div>
        {showToast && (
            <div id="toast-container" className="position-fixed top-0 start-50 translate-middle-x p-3"
                 style={{zIndex: 11}}>
                <ToastMessage id="errorToast" message={errorMessage} onClose={() => setShowToast(false)} role="alert"/>
            </div>
        )}
        <div className="container" style={{maxWidth: '90%'}}>
            <h1><span className="bi-people"/> {localizedTexts.title}</h1>
            <div className="row">
                <div className="col-3 d-flex align-items-center" style={{zIndex: '15'}}>
                    <span className="h3">{localizedTexts.labelOrganization}: </span>&nbsp;&nbsp;
                    <WinFireDropDown
                        buttonText={selectedOrganization ?? localizedTexts.textOrganizationSelect ?? ""}
                        elements={organizations.map(o => ({
                            sourceObject: o,
                            key: o.organizationNumber,
                            text: o.organizationName,
                            selectFunction: selectOrganization
                        } as WinFireDropDownElement))}
                        elementFilter={(a: string, b: { organizationNumber: string; organizationName: string }) => {
                            return b.organizationName.toLowerCase().includes(a.toLowerCase()) || b.organizationNumber.includes(a);
                        }}
                    />
                </div>
                <div className="col-3 d-flex align-items-center" style={{zIndex: '15'}}>
                    <span className={"h3"}>{localizedTexts.labelDiscipline}: </span>&nbsp;&nbsp;<WinFireDropDown
                    buttonText={selectedDiscipline.name ?? ""} elements={disciplinesRegistered?.sort((a, b) => a.id! - b.id!).map(d => {
                    return {
                        key: d.name ?? "", text: d.name, sourceObject: d, selectFunction: (d: object) => {
                            setSelectedDiscipline(d)
                        }
                    } as WinFireDropDownElement
                }) ?? []}
                    elementFilter={(a: string, b: Discipline) => (b.name?.toLowerCase().indexOf(a.toLowerCase()) ?? -1) > -1}/>
                </div>

                <div className="col-3 d-flex align-items-center">
                    <span className="h3">{localizedTexts.labelSearch}: </span>&nbsp;&nbsp;
                    <input placeholder="suchen" onChange={handleChange}/>
                </div>
            </div>
            <div className="row">
                <table className="table table-striped table-responsive sticky">
                    <thead>
                    <tr>
                        <th className="sticky"><span className="bi-printer"/></th>
                        <th className="sticky">{localizedTexts.headerBarcode}</th>
                        <th className="sticky">{localizedTexts.headerLastName}</th>
                        <th className="sticky">{localizedTexts.headerFirstName}</th>
                        <th className="sticky">{localizedTexts.ageOfBirth}</th>
                        <th className="sticky">{localizedTexts.headerCity}</th>
                        {selectedDiscipline.shootingProgramDefinitions?.map(sp => (
                            <th key={"sp" + sp.id} className="rotate sticky" style={{textAlign: 'center'}}>
                                <div>{sp.name}</div>
                            </th>
                        ))}
                        <th className="sticky">{localizedTexts.headerOrganization}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {availableParticipants
                        .filter(participant => participant.organization?.number === selectedOrganizationNumber
                            && (
                                participant.person?.lastName?.toLowerCase().includes(search)
                                || participant.person?.firstName?.toLowerCase().includes(search)
                                || participant.person?.licenseNumber?.toLowerCase().includes(search))
                        )
                        .sort((a, b) => a.person?.lastName?.localeCompare(b.person?.lastName ?? '') ?? 0)
                        .map(p => (
                            <tr key={p.participantNumber}>
                                <td></td>
                                <td>{p.participantNumber}</td>
                                <td>{p.person?.lastName}</td>
                                <td>{p.person?.firstName}</td>
                                <td>{new Date(p.person?.birthday!).getFullYear()}</td>
                                <td>{p.person?.city}</td>
                                {selectedDiscipline.shootingProgramDefinitions?.map(sp => {
                                    const registrationCount = p.exerciseRegistrations?.find(esp => esp.shootingProgramDefinitionId == sp.id)?.count || 0
                                    //const registrationCount = p.exerciseRegistrations?.filter(er => er.shootingProgramDefinitionId === sp.id).length || 0;
                                    return (
                                        <td style={{fontWeight: registrationCount > 0 ? 'bold' : 'normal', textAlign: 'center'}}>{registrationCount > 0 ? registrationCount : 0}</td>
                                    );
                                })}
                                <td>{p.organization?.name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="row mt-3">
                <span>{localizedTexts.textTimeslotCount}: {timeSlotData.filter(t => t.registeredDisciplineId === selectedDiscipline.id).length}</span>
            </div>
            <div className="row mt-3">
                <table className="table table-striped table-responsive sticky">
                    <thead>
                    <tr>
                        <th><span className="bi-printer"/></th>
                        <th>{localizedTexts.headerDate}</th>
                        <th>{localizedTexts.headerStartTime}</th>
                        <th>{localizedTexts.headerEndTime}</th>
                        <th>{localizedTexts.headerLaneNr}</th>
                        <th>{localizedTexts.headerShootingRangeName}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {timeSlotData
                        .filter(t => t.registeredDisciplineId === selectedDiscipline.id)
                        .map(t => (
                            <tr key={t.shootingRangeName + t.startTime!.toString() + t.laneNr}>
                                <td></td>
                                <td>{new Date(t.startTime!).toLocaleDateString([], {day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
                                <td>{new Date(t.startTime!).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}</td>
                                <td>{addMinutes(new Date(t.startTime!), t.durationInMinutes ?? 0).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}</td>
                                <td>{t.laneNr}</td>
                                <td>{t.shootingRangeName}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>


};

export default EventOrganisationOverview;