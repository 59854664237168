import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "overview" : "Übersicht",
        "title" : "Vereinsübersicht",
        "labelOrganization":"Verein",
        "labelDiscipline":"Disziplin",
        "labelSearch":"Suchen",
        "labelLabels":"Standblätter",
        "textOrganizationSelect":"Verein auswählen...",
        "headerBarcode":"SchützenNr.",
        "headerFirstName":"Vorname",
        "headerLastName":"Nachname",
        "ageOfBirth": "Jg.",
        "headerCity":"Ort",
        "headerDate":"Datum",
        "headerStartTime":"Von",
        "headerEndTime":"Bis",
        "headerLaneNr":"Scheibe",
        "headerShootingRangeName": "Schiessanlage",
        "headerOrganization":"Verein",
        "headerActions":"Aktionen",
        "shooteroffsetmessage":"Wieviele Schützen wurden bereits auf dieser Seite gedruckt?",
        "textTimeslotCount":"Anzahl Rangeure"
    },
    "fr": {
        "overview" : "Aperçu",
        "title" : "Aperçu de les societes",
        "labelDiscipline":"Discipline",
        "headerLaneNr":"cible",
        "ageOfBirth": "neé",
        "textTimeslotCount":"no. de rangeurs"
    },
    "it": {
        "overview" : "Panoramica",
        "title" : "Panoramica dell'associazione",
    },
    "en": {
        "overview" : "Overview",
        "title" : "Organization Overview",
    }
    }
)
export default localizedTexts;