import React, {useEffect, useState} from "react";
import ToastMessage from "../common/components/ToastMessage";
import localizedTexts from "./texts/EventV2TimeSlotReservation.texts";
import WinFireDropDown, {WinFireDropDownElement} from "../common/components/WinFireDropDown";
import ApiClientFactory from "./models/ApiClientFactory";
import {useParams} from "react-router";
import {
    Discipline, Distance, Organization,
    OrganizationTimeSlotInformation, OrganizationWithTimeSlotInformation,
    ShootingRange,
    ShootingRangeTimeSlotReservationInformations
} from "./models/Models";


const EventV2TimeSlotReservation : React.FC = () => {
        const apiClient = ApiClientFactory.GetApiClient();

        const {eventId} = useParams();

        const [shootingRanges, setShootingRanges] = useState([] as ShootingRange[]);
        const [selectedShootingRange, setSelectedShootingRange] = useState({} as ShootingRange);
        const [selectedDate, setSelectedDate] = useState(undefined as string | undefined);

        const [timeSlotInfo, setTimeSlotInfo] = useState({} as ShootingRangeTimeSlotReservationInformations);
        const [organizationInfos, setOrganizationInfos] = useState(new Map<string, OrganizationWithTimeSlotInformation>);
        const [organizations, setOrganizations] = useState([] as Organization[]);
        const [selectedOrganization, setSelectedOrganization] = useState({} as Organization);

        const [laneNumbers, setLaneNumbers] = useState(new Map<number, number[]>);
        const [times, setTimes] = useState([] as string[]);
        const [distances, setDistances] = useState([] as Distance[]);
        const [selectedDistance, setSelectedDistance] = useState({} as Distance);
        const [isMorningSelected, setIsMorningSelected] = useState(true);

        const [fromTimeSlots, setFromTimeSlots] = useState([] as number[]);
        const [toTimeSlots, setToTimeSlots] = useState([] as number[]);

        const [showToast, setShowToast] = useState(false);
        const [errorMessage, setErrorMessage] = useState('');

        useEffect(() => {
            loadData();
            loadAllRegisteredOrganizations();
        }, []);

        async function loadData(){
            const shootingRangesResult = await apiClient.api.eventsV2ShootingRangeManagementDetail(`${eventId}`);
            let selectedShootingRange: ShootingRange | undefined = undefined;
            if (shootingRangesResult.ok){
                setShootingRanges(shootingRangesResult.data);
                if (shootingRangesResult.data.length > 0){
                    selectedShootingRange = shootingRangesResult.data[0]
                    setSelectedShootingRange(selectedShootingRange);
                }
            }else{
                setErrorMessage(localizedTexts.errorGeneralText??"");
                setShowToast(true);
            }
        }

        function selectRange(shootingRange: ShootingRange){
            setSelectedShootingRange(shootingRange);
            if (selectedDate){
                loadTimeSlotInfo(shootingRange.number??"", selectedDate, isMorningSelected, true);
            }
        }

        function selectDate(date:string){
            setSelectedDate(date)
            if (selectedShootingRange){
                loadTimeSlotInfo(selectedShootingRange.number??"", date, isMorningSelected, true);
            }
        }

        async function loadTimeSlotInfo(shootingRange: string, date: string, isMorning:boolean, forceUpdateSelectedDistance: boolean = false){
            const timeSlotInfo = await apiClient.api.eventsV2ShootingRangeManagementTargetreservationtimeslotsDetail(shootingRange, date, isMorning, `${eventId}`);
            if (timeSlotInfo.ok){
                setTimeSlotInfo(timeSlotInfo.data);
                const laneNumbers = new Map<number, number[]>();
                const timesArr = new Array<string>();
                const distancesArr = new Array<Distance>();
                let selectedDistanceOrUndefined:Distance = selectedDistance;
                timeSlotInfo.data.timeSlotReservations?.forEach(tsr => {

                    var lanes = laneNumbers.get(tsr.distance!.id!) ?? [];

                   if (lanes.findIndex(ln => ln == tsr.laneNumber!) == -1){
                       lanes.push(tsr.laneNumber!);
                       laneNumbers.set(tsr.distance!.id!, lanes);
                   }
                    if (timesArr.findIndex(t => t == tsr.startTime!) == -1){
                        timesArr.push(tsr.startTime!);
                    }
                    if (distancesArr.findIndex(t => t.id == tsr.distance!.id!) == -1){
                        distancesArr.push(tsr.distance!);
                        if (selectedDistanceOrUndefined.id == 0 || forceUpdateSelectedDistance){
                            selectedDistanceOrUndefined = tsr.distance!;
                        }
                    }
                });

                setLaneNumbers(laneNumbers);
                setTimes(timesArr);
                setDistances(distancesArr);
                setSelectedDistance(selectedDistanceOrUndefined!);
                setFromTimeSlots([]);
                setToTimeSlots([]);
            }else{
                setErrorMessage(localizedTexts.errorGeneralText??"");
                setShowToast(true);
            }
        }

        async function loadAllRegisteredOrganizations(){
            const result = await apiClient.api.eventsV2OrganizationsRegisteredDetail(`${eventId}`);
            if (result.ok){
                setOrganizations(result.data);
            }else{
                setErrorMessage(localizedTexts.errorGeneralText??"");
                setShowToast(true);
            }
        }

        function selectOrganization(organization: Organization){
            setSelectedOrganization(organization);
        }

        function selectDistance(discipline:Distance){
            setSelectedDistance(discipline);
        }

        async function switchEntries(){
            const result = await apiClient.api.eventsV2ShootingRangeManagementTargetreservationtimeslotsSwapCreate(`${eventId}`, {fromSlotIds: fromTimeSlots, toSlotIds: toTimeSlots});
            if (result.ok){
                await loadTimeSlotInfo(selectedShootingRange.number!, selectedDate!, isMorningSelected);
            }else{
                setErrorMessage(localizedTexts.errorGeneralText??"");
                setShowToast(true);
            }
        }

        function switchMorningAfternoon(isMorning:boolean){
            setIsMorningSelected(isMorning);
            if (selectedShootingRange && selectedDate){
                loadTimeSlotInfo(selectedShootingRange.number??"", selectedDate??"", isMorning);
            }
        }

        async function lockSelectedOrganization(){
            if (selectedOrganization.number && selectedShootingRange.number && selectedDate && selectedDistance){
                await apiClient.api.eventsV2ShootingRangeManagementTargetreservationtimeslotsLockDetail(selectedOrganization.number, selectedShootingRange.number, selectedDistance.id!, selectedDate, isMorningSelected, `${eventId}`);
                loadTimeSlotInfo(selectedShootingRange.number??"", selectedDate??"", isMorningSelected);
            }

        }

    return <div>
        {showToast && (
            <div id="toast-container" className="position-fixed top-0 start-50 translate-middle-x p-3"
                 style={{zIndex: 11}}>
                <ToastMessage id="errorToast" message={errorMessage} onClose={() => setShowToast(false)} role="alert"/>
            </div>
        )}
        <h1>{localizedTexts.title}</h1>
        <div className={"card mb-3"}>
            <div className={"card-header"}>
                <h5>{localizedTexts.labelSelection}</h5>
            </div>
            <div className={"card-body"}>
                <div className={"row"}>
                    <div className={"col-2"}>
                        <label>{localizedTexts.labelShootingRange}:</label> <WinFireDropDown
                        buttonText={selectedShootingRange?.name ?? ""}
                        elements={!shootingRanges ? [] : shootingRanges.map(sr => {
                            return {
                                key: sr.number,
                                text: sr.name,
                                sourceObject: sr,
                                selectFunction: selectRange
                            } as WinFireDropDownElement
                        })} elementFilter={() => true}/>
                    </div>
                    <div className={"col-2"}>
                        <div className={"row"}>
                            <div className={"col-auto"}>
                                <label className={"form-label"}
                                       htmlFor={"datePicker"}>{localizedTexts.labelDate}:</label>
                            </div>
                            <div className={"col-auto"}>
                                <input id={"datePicker"} type={"date"} className={"form-control"} value={selectedDate}
                                       onChange={(evt) => {
                                           selectDate(evt.currentTarget.value)
                                       }}/>
                            </div>
                            <div className={"col-auto"}>
                                <div className="form-check form-switch">
                                    <label className="form-check-label"
                                           htmlFor="flexSwitchCheckDefault1">{localizedTexts.labelMorning}</label>
                                    <input className="form-check-input" type="checkbox" role="switch"
                                           id="flexSwitchCheckDefault1"
                                           checked={isMorningSelected}
                                           onChange={(evt) => {
                                               if (evt && evt.currentTarget) {
                                                   switchMorningAfternoon(evt.currentTarget.checked);
                                               }
                                           }}/></div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-2"}>
                                <label>{localizedTexts.labelDiscipline}:</label> <WinFireDropDown
                                buttonText={selectedDistance?.name ?? ""}
                                elements={!distances ? [] : distances.map(d => {
                                    return {
                                        key: d.name,
                                        text: d.name,
                                        sourceObject: d,
                                        selectFunction: selectDistance
                                    } as WinFireDropDownElement
                                })}
                                elementFilter={() => true}/>
                    </div>

                    <div className={"col-4"}>
                        <div className={"row"}>
                            <div className={"col-auto"}>
                                <label>{localizedTexts.labelOrganization}:</label> <WinFireDropDown
                                buttonText={selectedOrganization.name ?? ""}
                                elements={organizations.map(o => {
                                    return {
                                        key: o.number,
                                        text: o.name,
                                        sourceObject: o,
                                        selectFunction: selectOrganization
                                    } as WinFireDropDownElement
                                })}
                                elementFilter={(name: string, obj: Organization) => (obj.name?.toLowerCase().indexOf(name?.toLowerCase()) ?? 0) > -1}/>
                            </div>
                                <div className={"col-auto"}>
                                    <button className={"btn btn-primary"} onClick={() => {
                                        lockSelectedOrganization()
                                    }}>{localizedTexts.labelLockOrganization}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>

        <div className={"card mb-3"}>
            <div className={"card-header"}>
                <h5>{localizedTexts.labelSwitchEntries}</h5>
            </div>
            <div className={"card-body"}>
                <div className={"row"}>
                    <div className={"col-1"}>
                        <button className={"btn btn-primary"}
                                disabled={fromTimeSlots.length == 0 || fromTimeSlots.length != toTimeSlots.length}
                                onClick={() => {
                                    switchEntries()
                                }}>{localizedTexts.labelSwitchEntries}</button>
                    </div>
                    <div className={"col-1"}>
                        {localizedTexts.labelFrom} : {fromTimeSlots.length}
                    </div>
                    <div className={"col-1"}>
                        {localizedTexts.labelTo} : {toTimeSlots.length}
                    </div>
                    <div className={"col-1"}>
                        <button className={"btn btn-primary"}
                                onClick={() => {
                                    setToTimeSlots([]);
                                    setFromTimeSlots([]);
                                }}>{localizedTexts.labelClear}</button>
                    </div>
                </div>

            </div>
        </div>

        <table className={"table table-striped"}>
            <thead>
            <tr>
                <th></th>
                {
                    selectedDistance ? laneNumbers?.get(selectedDistance?.id ?? 0)?.map(ln =>
                        <th>{localizedTexts.headerLaneNumber} {ln}
                            <div className="">
                                <button className={"btn btn-primary"} onClick={() => {
                                    timeSlotInfo.timeSlotReservations
                                        ?.filter(tsr => tsr.laneNumber == ln && (tsr.distance?.id == selectedDistance.id)).forEach(tsr => {
                                        setFromTimeSlots(from => {
                                            let ret = [];
                                            ret.push(...from);
                                            if (from.findIndex(f => f == tsr.id!) == -1){
                                                ret.push(tsr.id!);
                                            }
                                            return ret;
                                        });
                                    })
                                }}>{localizedTexts.labelFrom}</button>
                                &nbsp;
                                <button className={"btn btn-primary"} onClick={() => {
                                    timeSlotInfo.timeSlotReservations
                                        ?.filter(tsr => tsr.laneNumber == ln && (tsr.distance?.id == selectedDistance.id)).forEach(tsr => {
                                        setToTimeSlots(from => {
                                            let ret = [];
                                            ret.push(...from);
                                            if (from.findIndex(f => f == tsr.id!) == -1){
                                                ret.push(tsr.id!);
                                            }
                                            return ret;
                                        });
                                    })
                                }}>{localizedTexts.labelTo}</button>
                            </div>
                        </th>) : <span></span>
                }
            </tr>
            </thead>
            <tbody>
            {
                times.map(time => {
                    const items = timeSlotInfo.timeSlotReservations
                        ?.filter(tsr => tsr.startTime == time && (tsr.distance?.id == selectedDistance.id));
                    var firstItem = items && items.length > 0 ? items[0] : undefined;

                    return <tr>
                        <th>{Intl.DateTimeFormat("DE-CH", {
                            hour: '2-digit',
                            minute: '2-digit'
                        }).format(Date.parse(time))} {firstItem?.shootingProgramDefinitionName}</th>
                        {
                            items?.map(tsr => {
                                let addCssClass = tsr.stateId == 1 ? "bi-check-circle-fill text-success " : "bi-x-circle-fill text-danger "
                                let infoData = tsr.stateId == 1 ? "" : `(${tsr.id})`
                                let selectedTrueCssClass = selectedOrganization && selectedOrganization.number == tsr.assignedOrganizationNumber ? "bg-warning-subtle " : " ";
                                if (tsr.stateId == 3) {
                                    addCssClass = "bi-check2-circle text-success";
                                    selectedTrueCssClass += "bg-warning ";
                                    infoData = "";
                                }
                                if (!tsr.assignedOrganizationNumber || tsr.assignedOrganizationNumber == ''){
                                    selectedTrueCssClass = "bg-info";
                                }

                                return <td className={selectedTrueCssClass}>
                                    <span
                                        className={"bi " + addCssClass}></span> {infoData} {organizations.find(o => o.number == tsr.assignedOrganizationNumber)?.name}
                                            <div className="form-check form-switch">
                                                <label className="form-check-label"
                                                       htmlFor="flexSwitchCheckDefault1">{localizedTexts.labelFrom}</label>
                                                <input className="form-check-input" type="checkbox" role="switch"
                                                       id="flexSwitchCheckDefault1"
                                                       checked={fromTimeSlots.findIndex(f => f == tsr.id) > -1}
                                                       onChange={(evt) => {
                                                           if (evt && evt.currentTarget) {
                                                               setFromTimeSlots(from => {
                                                                   let ret = [];
                                                                   if (evt.currentTarget.checked) {
                                                                       ret.push(...from);
                                                                       ret.push(tsr.id!);
                                                                   } else {
                                                                       ret = from.filter(f => f !== tsr.id!);
                                                                   }
                                                                   return ret;
                                                               });
                                                           }
                                                       }}/></div>
                                            <div className="form-check form-switch">
                                                <label className="form-check-label"
                                                       htmlFor="flexSwitchCheckDefault2">{localizedTexts.labelTo}</label>
                                                <input className="form-check-input" type="checkbox" role="switch"
                                                       id="flexSwitchCheckDefault2"
                                                       checked={toTimeSlots.findIndex(f => f == tsr.id) > -1}
                                                       onChange={(evt) => {
                                                           if (evt && evt.currentTarget) {
                                                               setToTimeSlots(to => {
                                                                   let ret = [];
                                                                   if (evt.currentTarget.checked) {
                                                                       ret.push(...to);
                                                                       ret.push(tsr.id!);
                                                                   } else {
                                                                       ret = to.filter(f => f !== tsr.id!);
                                                                   }
                                                                   return ret;
                                                               });
                                                           }
                                                       }}/></div>
                                        </td>
                                    })
                                }
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
            }

            export default EventV2TimeSlotReservation;